

form button[type="submit"] {
    width: 100%;
    --bs-border-radius: 2rem;
    --bs-btn-bg: #ff5f1f;
    --bs-btn-border-color: #ff6f35;
    --bs-btn-hover-bg: color-mix(in hsl, #ff5f1f 90%, white);
    --bs-btn-hover-border-color: #ff6f35;
    --bs-btn-active-color: black;
    --bs-btn-active-bg: #19ff85;
    --bs-btn-active-border-color: color-mix(in hsl, #19ff85 90%, black);
}

.btn.btn-primary {
    --bs-border-radius: 2rem;
}

.btn.btn-primary.orange {
    --bs-btn-bg: #ff5f1f;
    --bs-btn-border-color: #ff6f35;
    --bs-btn-hover-bg: color-mix(in hsl, #ff5f1f 90%, white);
    --bs-btn-hover-border-color: #ff6f35;
}

.input-group-text {
    --bs-border-radius: 2rem;
    --bs-tertiary-bg: white;
}

.form-control {
    --bs-border-radius: 2rem;
}

input.form-control {
    padding: 0.5rem 1rem;
}

select.form-select {
    --bs-border-radius: 2rem;
}

textarea.form-control {
    --bs-border-radius: 2rem;
    padding: 0.5rem 1rem;
}

#sticky-option .form-check {
    border: 1px solid var(--bs-border-color);
    border-radius: 2rem;
    padding: 0.5rem 1rem;
    padding-left: 2.2rem;
    margin-bottom: 0.5rem;
}

::-webkit-scrollbar {
  width: 0.25rem;
}

::-webkit-scrollbar-track {
  background: #f1f1f1;
}

::-webkit-scrollbar-thumb {
  background: #6666ff;
}

::-webkit-scrollbar-thumb:hover {
  background: #9999ff;
}
